@tailwind base;
@tailwind components;
@tailwind utilities;

@layer base {
  section {
    @apply font-montserrat border border-navBorderColor text-navProfileColor;
  }
}

  
.card-guide {
  box-shadow: 1px 1px 24px 2px #eee;
}


.form-group label {
  font-weight: 700;
  color: #777;
}
label {
  display: inline-block;
  max-width: 100%;
  margin-bottom: 5px;
  font-weight: bold;
}
.form-control:focus {
  border-color: #66afe9;
  outline: 0;
  -webkit-box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075),
    0 0 8px rgba(102, 175, 233, 0.6);
  box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075),
    0 0 8px rgba(102, 175, 233, 0.6);
}
.form-control {
  padding-top: 8px;
  padding-bottom: 8px;
  height: 36px;
}
.input-lg {
  padding: 10px 6px !important;
  font-size: 18px;
  line-height: 1.3333333;
  border-radius: 6px;
}

.tooltipForm {
  position: relative;
  display: inline-block;
  cursor: pointer;
}

.tooltipForm .tooltiptext {
  margin-left: 13px;
  visibility: hidden;
  width: 400px;
  background-color: #fff;
  color: #777;
  border: 1px solid rgba(0, 0, 0, 0.2);
  /* text-align: center; */
  border-radius: 6px;
  padding: 9px 14px;
  position: absolute;
  z-index: 1;
  left: 105%;
  top: 50%;
  transform: translateY(-50%);
  opacity: 0;
  transition: opacity 0.3s;
  font-family: "Helvetica Neue", Helvetica, Arial, sans-serif;
  font-size: 14px;
  line-height: 1.42857143;
  font-weight: normal;
}

.tooltipForm .tooltiptextleft {
  visibility: hidden;
  opacity: 0;
  width: 400px;
  background-color: #fff;
  color: #777;
  border: 1px solid rgba(0, 0, 0, 0.2);
  border-radius: 6px;
  padding: 9px 14px;
  position: absolute;
  top: 50%;
  right: 129%; 
  transform: translateY(-50%); 
  z-index: 1;
  transition: opacity 0.3s ease;
  font-family: "Helvetica Neue", Helvetica, Arial, sans-serif;
  font-size: 14px;
  line-height: 1.42857143;
  font-weight: normal;
}

.tooltipForm:hover .tooltiptextleft {
  visibility: visible;
  opacity: 1;
}
.tooltipForm .tooltiptextleft::after {
  content: " ";
  position: absolute;
  top: 50%;
  left: 99%; 
  transform: translateY(-50%) rotate(135deg); 
  width: 10px;
  height: 10px;
  border-left: 1px solid rgba(0, 0, 0, 0.25); 
  border-top: 1px solid rgba(0, 0, 0, 0.25); 
  background-color: #fff;
}





.tooltipForm:hover .tooltiptext {
  visibility: visible;
  opacity: 1;
}

.modalForm {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background: white;
  padding: 20px;
  border-radius: 10px;
  max-width: 500px;
  width: 100%;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.2);
}

.modal-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  background: rgba(0, 0, 0, 0.5);
  z-index: 99999999;

}
.upload-btn {
  background-color: #dadada;
  color: black;
  padding: 4px 10px;
  display: inline-block;
  border-radius: 4px;
  border: 1px solid black;
  margin-top: 5px;
  cursor: pointer;
  font-weight: 600;
}

.preview_title_img {
  top: -24px;
  color: #000;
  font-weight: 600;
}

.jaCheckBox:checked {
  background: linear-gradient(
    177deg,
    rgb(92 112 94) -1.1%,
    rgb(9 255 78) 100.7%
  );
  border-color: green;
}

.jaCheckBox:checked:before {
  content: "";
  top: 0px;
  position: relative;
  font-size: 20px;
  background-image: url(/public/images/becomeS/check.svg);
  background-repeat: no-repeat;
  background-size: 60%;
  background-position: center;
  width: 100%;
  height: 100%;
  display: block;
  border-color: green;
  border-radius: 50%;
}

.jaCheckBox {
  -webkit-appearance: none;
  height: 40px;
  width: 40px;
  min-width: 40px;
  transition: 0.1s;
  text-align: center;
  font-weight: 600;
  color: white;
  border-radius: 3px;
  outline: none !important;
  border: 1px solid #7a7a7a;
  border-radius: 50%;
}
.jaCheckBox:hover {
  cursor: pointer;
}
.btn-color-c {
  border: 1px solid #6f0031;
  padding: 12px 30px;
  background: rgba(102, 0, 51, 1) !important;
  color: rgba(255, 247, 247, 1) !important;

  display: inline-block;
  border-radius: 4px;
  margin-bottom: 0;
  font-size: 14px;
  font-weight: normal;
  line-height: 1.42857143;
  text-align: center;
  white-space: nowrap;
  vertical-align: middle;
}

.footerBecomeSpeaker {
  background: #f7f7f7;
  width: 100%;
  padding: 10px 0px;
  /* float: left; */
  font-size: 12px;
}

div.footernav > a:before {
  content: "";
  position: absolute;
  left: 5px;
  top: 50%;
  transform: translateY(-50%); 
  width: 1px;
  height: 12px;
  background: #333; 
}

div.footernav > a:first-child:before {
  display: none;
}

div.footernav > a {
  position: relative;
  padding-left: 10px; 
}

/* .become-speaker-body {
  min-height: calc(100vh - 113px);
} */

.tooltipForm .tooltiptext::after {
  top: 50%;
  right: 98%;
  transform: rotate(-45deg) translateY(-50%);
  border-width: 1px 0px 0px 0px;
  content: " ";
  border-left-width: 1px;
  position: absolute;
  display: block;
  width: 10px;
  height: 10px;
  border-color: rgba(0, 0, 0, 0.25);
  border-style: solid;
  background: #fff;
}


section {
  overflow: auto !important;
  position: absolute !important;
  width: 100vw !important;
}
.become-speaker-body{
  width: 86vw !important;
}






