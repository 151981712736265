@tailwind base;
@tailwind components;
@tailwind utilities;

@layer base {
  section {
    @apply font-montserrat border border-navBorderColor text-navProfileColor bg-btnColor;
  }
}

div.ReactVirtualized__Grid__innerScrollContainer {
  /* overflow-x: scroll !important; 
  overflow-y: scroll !important; */
  overflow: hidden !important;
}

/* Customize the track of the scrollbar */
div.ReactVirtualized__Grid__innerScrollContainer ::-webkit-scrollbar-track {
  -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3) !important;
  border-radius: 10px !important;
  background-color: #F5F5F5 !important;
}

/* Customize the scrollbar itself */
div.ReactVirtualized__Grid__innerScrollContainer ::-webkit-scrollbar {
  width: 12px !important;
  background-color: #F5F5F5 !important;
}

/* Customize the scrollbar thumb */
div.ReactVirtualized__Grid__innerScrollContainer ::-webkit-scrollbar-thumb {
  border-radius: 10px !important;
  -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, .3) !important;
  background-color: #D62929 !important;
}

/* .ReactVirtualized__Table__headerRow {
  height: 60px !important;
} */

.modalFormDelete {
  position: absolute;
  top: 20%;
  left: 50%;
  transform: translate(-50%, -50%);
  background: white;
  padding: 20px;
  border-radius: 10px;
  max-width: 500px;
  width: 100%;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.2);
}

.modal-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  background: rgba(0, 0, 0, 0.5);
}

.status {
  height: 20px;
  line-height: 21px;
  display: inline-block;
  text-align: center;
  padding: 0 8px;
  border-radius: 12px;
  font-weight: 700;
  font-size: 11px;
  letter-spacing: 0.48px;
  text-transform: uppercase;
}

.modalFormView {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background: white;
  padding: 20px;
  border-radius: 10px;
  max-width: 70vw;
  max-height: 90vh;
  overflow-y: visible;
  width: 100%;
  box-shadow: 0 4px 20px rgba(0, 0, 0, 0.3);
  overflow-y: auto;
}

.modal-overlay-view {
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  background: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
}


.become-listing .ReactVirtualized__Table__headerRow {
  height: 57px !important;
}