body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;

  height: 100vh !important;
  overflow: hidden !important;
}



code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

.rate-area {
  float: left;
  border-style: none;
}

.rate-area:not(:checked) > input {
  position: absolute;
  top: -9999px;
  clip: rect(0, 0, 0, 0);
}

.rate-area:not(:checked) > label {
  float: right;
  width: 0.8em;
  overflow: hidden;
  white-space: nowrap;
  cursor: pointer;
  font-size: 130%;
  color: lightgrey;
}

.rate-area:not(:checked) > label:before {
  content: "★";
}

.rate-area > input:checked ~ label {
  color: gold;
}

.rate-area:not(:checked) > label:hover,
.rate-area:not(:checked) > label:hover ~ label {
  color: gold;
}

.rate-area > input:checked + label:hover,
.rate-area > input:checked + label:hover ~ label,
.rate-area > input:checked ~ label:hover,
.rate-area > input:checked ~ label:hover ~ label,
.rate-area > label:hover ~ input:checked ~ label {
  color: gold;
}

.star-rating .star-container {
  padding-right: 4px !important;
  padding-left: 4px !important;
}
.star-rating .star-container svg {
  width: 20px !important;
}

/* loader */
.loaderTemp {
  width: 24px;
  height: 24px;
  border-radius: 50%;
  display: inline-block;
  position: relative;
  border: 3px solid;
  border-color: lightgray lightgray transparent;
  box-sizing: border-box;
  animation: rotation 1s linear infinite;
}

.userInfoDetail {
  text-align: center;
}

.formStyle {
  display: block;
  width: 100%;
  margin-bottom: 20px;
}
.pig-rating.formStyle .rating-hover {
  margin-right: 10px;
}
.formStyle .star-ratings .star-container .widget-svg,
.formStyle .star-ratings .star-container {
  height: 20px !important;
}

.userlisting table th font {
  /* transform: rotate(-5deg);
    display: inline-block; */
}
.userlisting table .th-assessment {
  min-width: 30px;
  max-width: 30px;
  width: 30px;
}
.userlisting table .th-assessment font {
  /* transform: rotate(135deg);
  display: inline-block;
  padding-top: 40px;
  margin-bottom: -15px;
  position: relative;
  top: 40px; */
  writing-mode: vertical-rl;
  text-orientation: mixed;
}
.userlisting table .th-rating {
  min-width: 130px;
}
.userlisting .dataTable tr td.user_package_text {
  min-width: 30px;
  max-width: 30px;
  width: 30px;
}

.userlisting table .th-pigrating {
  min-width: 185px;
}
.userlisting table .th-viewaccounts {
  min-width: 120px;
}
.userlisting table .th-assessment font a {
  color: #666;
}
.userlisting table th a {
  color: #666;
}

.userlisting tbody tr td {
  /*padding-left: 18px !important;*/
}
.userlisting tbody tr td.user_role_text {
  /* min-width: 100px; */
  /* max-width: 100px; */
}
.userlisting table th {
  vertical-align: bottom;
}

.userlisting tbody tr td .form-group > div {
  padding: 0;
}

.userlisting .dataTable tr td .form-group > div img {
  padding-right: 4px !important;
  padding-left: 4px !important;
}

.accountManagement .th-action {
  text-align: right;
}
.accountManagement .td-action {
  text-align: right;
}
.accountManagement .action-span {
  padding-right: 13px;
}
.accountManagement .action-span i.fa {
  margin: 0px 5px;
  cursor: pointer;
}

.userManagement th.th-status {
  text-align: right;
}

.userManagement td.userStatus {
  text-align: right;
}
.userManagement td.userStatus label {
  margin-right: 7px;
}
.userlisting .dataTable tr td.user_email {
  min-width: 150px;
  max-width: 150px;
  width: 150px;
}
td.ellipsis font {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  width: 100%;
  display: inline-block;
}

.min-wd-120 {
  min-width: 120px;
  max-width: 120px;
  width: 120px;
}

.modal.right.fade .modal-dialog {
  right: 0px;
  transition: opacity 0.3s linear, right 0.3s ease-out;
  margin-right: 0;
  margin-top: 0;
  height: 100%;
  max-width: 600px;
  margin-bottom: 0;
}
.modal.right.fade .modal-dialog .modal-content .modal-body {
  width: 100%;
  max-width: 100%;
  padding-top: 10px;
  height: calc(100% - 56px);
}
.modal.right.fade .modal-dialog .modal-content .modal-body textarea {
  min-height: 100px;
  border: 1px solid #ddd;
  border-radius: 5px;
  padding: 5px;
}
.modal.right.fade .modal-dialog .modal-header {
  border-bottom: 0;
  padding-bottom: 0;
}

.comments {
  padding: 20px 5px 0;
  height: calc(100% - 135px);
  overflow: auto;
}
.comments-list {
  margin-bottom: 20px;
  font-size: 15px;
  display: block;
}
.comments-msg {
  /* background: #f6f6f6; */
  width: 100%;
  display: inline-block;
  /* padding: 3px 40px 4px 10px; */
  padding: 25px 40px 25px 15px;
  box-shadow: 0px 0px 4px 2px #eee;
  position: relative;
  border-radius: 10px;
  /* word-break: break-all; */
}
.close-msg {
  position: absolute;
  right: 10px;
  cursor: pointer;
  font-weight: 700;
  top: 5px;
  font-size: 20px;
}
.button_link {
  border: none;
  background: #fff !important;
  text-decoration: underline;
  font-size: 16px;
}
.react-confirm-alert-overlay {
  z-index: 9999 !important;
}
.edit-msg {
  position: absolute;
  right: 26px;
  cursor: pointer;
  font-weight: 700;
  top: 5px;
  font-size: 20px;
}
.modal.right.fade .modal-dialog .modal-content {
  height: 100%;
  overflow-y: auto;
}
.modal.right.fade.in .modal-dialog {
  right: 0;
}

.comments-row {
  display: flex;
  gap: 20px;
}

.st-name {
  width: 50px;
  height: 50px;
  display: flex;
  align-items: center;
  justify-content: center;
  background: #eee;
  border-radius: 50px;
  font-size: 15px;
  font-weight: bold;
  color: #333;
}
.by-user {
  display: flex;
  justify-content: space-between;
  margin-bottom: 10px;
}
.posted-by {
  font-size: 14px;
  font-weight: 600;
  color: #333;
}

.comment-date {
  font-size: 12px;
  color: #bbb;
  font-weight: 500;
}
.comments-pl {
  width: 100%;
  display: inline-block;
  word-break: break-word;
  font-size: 15px;
}
.comments-data {
  width: 90%;
}

.scroll-table {
  height: calc(100% - 105px);
  overflow: auto;
  position: relative;
}

.table-responsive.custom-center-ui thead {
  position: sticky;
  top: 0;
  z-index: 1;
  background: #f3f3f3;
}
.userlisting {
  height: 100%;
}
.content-page > .content {
  margin-bottom: 0;
  padding: 10px 5px 0px 5px;
  margin-top: 0px;
  height: 100%;
}
.content-page > .content .container {
  height: 100%;
}

.content-page {
  margin-left: 240px;
  overflow: hidden;
  height: 100vh;
}
.fa {
  cursor: pointer;
}
.filter_options {
  position: relative;
  /* z-index: 99; */
}

.edited_tag {
  color: #aaa;
  margin-left: 20px;
}

span.speaker_chair {
  display: inline-block;
  width: 50px;
}

span.speaker_chair img {
  height: 20px;
}

.btn-cancel {
  padding: 10px 16px;
}

.become-btn{
  margin-top: 32px;
  display: flex;
  width: 95vw;
  justify-content: end;
}


.custom-ckeditor a {
  color: #6B172E !important;
}
