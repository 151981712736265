.formGeneralSettings{
    overflow: auto !important;
}

.switch {
    position: relative;
    display: inline-block;
    width: 50px; /* Adjusted width */
    height: 28px; /* Adjusted height */
  }
  
  .switch input {
    opacity: 0;
    width: 0;
    height: 0;
  }
  
  .slider {
    position: absolute;
    cursor: pointer;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: #ccc;
    -webkit-transition: 0.4s;
    transition: 0.4s;
  }
  
  .slider:before {
    position: absolute;
    content: "";
    height: 20px; /* Reduced height */
    width: 20px;  /* Reduced width */
    left: 4px;
    bottom: 4px;
    background-color: white;
    -webkit-transition: 0.4s;
    transition: 0.4s;
  }
  
  input:checked + .slider {
    background-color: #4f0e1f;
  }
  
  input:focus + .slider {
    box-shadow: 0 0 1px #4f0e1f;
  }
  
  input:checked + .slider:before {
    -webkit-transform: translateX(22px); /* Adjusted to match the new size */
    -ms-transform: translateX(22px);
    transform: translateX(22px);
  }
  
  /* Rounded sliders */
  .slider.round {
    border-radius: 28px; /* Adjusted to the smaller height */
  }
  
  .slider.round:before {
    border-radius: 50%;
  }
  